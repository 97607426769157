// src/pages/hiddenPage/HiddenFloatingBox.tsx
import React from "react";
import styled from "styled-components";
interface HiddenFloatingBoxProps {
    itemStatus: {
        name: boolean;
        phone: boolean;
        kakaoId: boolean;
        visaType: boolean;
        serviceType: boolean;
        desiredArea: boolean;
        desiredPrice: boolean;
        roomType: boolean;
        desiredDate: boolean;
        source: boolean;
        tags: boolean;
        isPrivacyChecked: boolean;
        isFormValid: boolean;
    };
    onSubmit: () => void;
}

const HiddenFloatingBox: React.FC<HiddenFloatingBoxProps> = ({ itemStatus, onSubmit }) => {


    return (
        <FloatingContainer>
            <Title>상담 양식 항목</Title>
            <Divider />
            <ItemList>
                <Item active={itemStatus.name}>이름<Star>*</Star></Item>
                <Item active={itemStatus.phone}>전화번호<Star>*</Star></Item>
                <Item active={itemStatus.kakaoId}>카카오톡 아이디<Star>*</Star></Item>
                <Item active={itemStatus.visaType}>비자 종류<Star>*</Star></Item>
                <Item active={itemStatus.serviceType}>희망 매물 종류<Star>*</Star></Item>
                <Item active={itemStatus.desiredArea}>희망 지역<Star>*</Star></Item>
                <Item active={itemStatus.desiredPrice}>희망 월세<Star>*</Star></Item>
                <Item active={itemStatus.roomType}>방 타입<Star>*</Star></Item>
                <Item active={itemStatus.desiredDate}>입주 희망 날짜<Star>*</Star></Item>
                <Item active={itemStatus.source}>유입 경로<Star>*</Star></Item>
                <Item active={itemStatus.tags}>일본 생활 키워드</Item>
                <Divider />
                <Item active={itemStatus.isPrivacyChecked}>개인정보 동의<Star>*</Star></Item>
            </ItemList>

            <SubmitButton
                layer={!itemStatus.isFormValid}
                onClick={onSubmit}
            >
                작성 완료
            </SubmitButton>
        </FloatingContainer>
    );
};


export default HiddenFloatingBox;

// Styled Components
const FloatingContainer = styled.div`
  position: fixed;
  top: 103px; // 헤더 영역 아래에 고정
  left: calc( (100vw/2) + 335px); // 1440 기준 + Container 오른쪽 30px 옆
  width: 220px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  @media screen and (max-width: 1439px) {
    display: none;
  }
`;

const Title = styled.h3`
  font-size: 14px;
  margin-bottom: 14px;
    color: #8F8F8F;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #ddd;
  margin-bottom: 16px;
`;

const ItemList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0 20px 7px;
`;

const Item = styled.li<{ active?: boolean }>`
  font-size: 14px;
  color: ${(props) => (props.active ? "#0123B4" : "#000")};
    margin: 18px 0;

  &::before {
    content: "•";
    color: ${(props) => (props.active ? "#0123B4" : "#8E8E8E")};
    display: inline-block;
      font-size: 20px;
    width: 6px;
    margin: 0 10px 0 -6px;
  }
`;
const Star = styled.span`
    font-size: 12px;
    margin-left:2px;
    color: #0222B4;
`;
const SubmitButton = styled.button<{layer: boolean}>`
    width: 100%;
    padding: 18px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 12px;
    cursor: ${(props) => (props.layer ? "default" : "pointer")};
    margin-top: 20px;

    background-color: ${(props) => (props.layer ? "#8E8E8E" : "#0222B4")};
    transition: background-color 0.3s ease;
    &:hover {
        background-color: ${(props) => (props.layer ? "#8E8E8E" : "#001887")};
    }
`;
