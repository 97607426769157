import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import usePropertyState from "../../../hooks/usePropertyState";

function HeaderBottom() {
  const { pagination, updatePagination } = usePropertyState();
  const [menuState, setMenuState] = useState<string>();

  const RENTMENU = [
    { name: "맨션", categoryNum: 1 },
    { name: "주택", categoryNum: 2 },
    { name: "단층맨션", categoryNum: 3 },
    { name: "기타", categoryNum: 4 },
  ];

  const SELLMENU = [
    { name: "토지", categoryNum: 5 },
    { name: "맨션", categoryNum: 6 },
    { name: "점포", categoryNum: 7 },
    { name: "주택", categoryNum: 8 },
    { name: "기타", categoryNum: 9 },
  ];

  const STOREMENU = [
    { name: "사무실", categoryNum: 10 },
    { name: "창고", categoryNum: 11 },
    { name: "점포", categoryNum: 12 },
    { name: "기타", categoryNum: 13 },
  ];

  const navigate = useNavigate();

  return (
    <HeaderBottomWrapper>
      <HeaderBottomInner>
        <NavigationDiv>
          <NavigtionItem
            onMouseOver={() => setMenuState("임대")}
            $isMouseOver={menuState === "임대"}
            onMouseOut={() => setMenuState("")}
          >
            <NavigationName>임대</NavigationName>
            {menuState === "임대" && (
              <DropDownWrapper>
                {RENTMENU.map((item, index) => (
                  <DropDownItem
                    key={index}
                    onClick={() => {
                      navigate("/mansion", { state: item.categoryNum });
                      updatePagination({ detailCategoryId: item.categoryNum });
                    }}
                  >
                    {item.name}
                  </DropDownItem>
                ))}
              </DropDownWrapper>
            )}
          </NavigtionItem>
          <NavigtionItem
            onMouseOver={() => setMenuState("매매")}
            onMouseOut={() => setMenuState("")}
            $isMouseOver={menuState === "매매"}
          >
            <NavigationName>매매</NavigationName>
            {menuState === "매매" && (
              <DropDownWrapper>
                {SELLMENU.map((item, index) => (
                  <DropDownItem
                    key={index}
                    onClick={() => {
                      navigate("/mansion", { state: item.categoryNum });
                      updatePagination({ detailCategoryId: item.categoryNum });
                    }}
                  >
                    {item.name}
                  </DropDownItem>
                ))}
              </DropDownWrapper>
            )}
          </NavigtionItem>
          <NavigtionItem
            onMouseOver={() => setMenuState("점포")}
            onMouseOut={() => setMenuState("")}
            $isMouseOver={menuState === "점포"}
          >
            <NavigationName>점포</NavigationName>
            {menuState === "점포" && (
              <DropDownWrapper>
                {STOREMENU.map((item, index) => (
                  <DropDownItem
                    key={index}
                    onClick={() => {
                      navigate("/mansion", { state: item.categoryNum });
                      updatePagination({ detailCategoryId: item.categoryNum });
                    }}
                  >
                    {item.name}
                  </DropDownItem>
                ))}
              </DropDownWrapper>
            )}
          </NavigtionItem>
          <NavigtionItem onClick={() => navigate("/houberMansion")}>
            <NavigationName>하우버맨션</NavigationName>
          </NavigtionItem>
          <NavigtionItem onClick={() => navigate("/residentreview")}>
            <NavigationName>입주후기</NavigationName>
          </NavigtionItem>
          <NavigtionItem onClick={() => navigate("/qna/home")}>
            <NavigationName>Q&A</NavigationName>
          </NavigtionItem>
        </NavigationDiv>
        <CounselingButton href={"https://pf.kakao.com/_qTEPj"}
                          target={"_blank"}
                          rel={"noopener noreferrer"}>
          문의하기
        </CounselingButton>
      </HeaderBottomInner>
    </HeaderBottomWrapper>
  );
}

export default HeaderBottom;

const HeaderBottomWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(164, 164, 164, 0.26);
  border-top: 1px solid rgba(164, 164, 164, 0.26);
  @media screen and (min-width: 1280px) {
    height: 78px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    height: 66px;
  }
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;
const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  @media screen and (min-width: 1280px) {
    width: 1280px;
    padding: 0 60px 0 47px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 1024px;
    padding: 0 45px 0 39px;
  }
  @media screen and (max-width: 1023px) {
    width: 425px;
  }
`;

const HeaderBottomInner = styled(HeaderBox)`
  justify-content: space-between;
`;

const NavigationDiv = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  @media screen and (min-width: 1280px) {
    gap: 9px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    gap: 11px;
  }
`;

const NavigtionItem = styled.div<{ $isMouseOver?: boolean }>`
  height: 100%;
  cursor: pointer;
  border-bottom: ${(props) => props.$isMouseOver && "2.66px solid #0123B4"};
  @media screen and (min-width: 1280px) {
    padding: 0 14px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    padding: 0 10px;
  }
`;

const NavigationName = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #333333;
  height: 100%;

  @media screen and (min-width: 1280px) {
    font-size: 23px;
    margin-bottom: 1px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    font-size: 19px;
    margin-bottom: 1px;
  }
`;

const CounselingButton = styled.a<{ isMouseOver?: boolean }>`
  color: #ffffff;
  background-color: #0123B4;
  cursor: pointer;
  font-weight: 600;
  font-family: "Noto Sans KR";
  text-align: center;
  align-content: center;
  border: none;
  text-decoration: none;
  @media screen and (min-width: 1280px) {
    width: 198px;
    height: 53px;
    font-size: 23px;
    border-radius: 25px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    width: 165px;
    height: 45px;
    font-size: 19px;
    border-radius: 22px;
  }
`;

const DropDownWrapper = styled.div`
  box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.1);
  position: absolute;
  color: #7b7b7b;
  background-color: white;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  z-index: 10;
  border-radius: 10px;
  letter-spacing: -0.05em;
  @media screen and (min-width: 1280px) {
    transform: translate(-14px, 2px);
    //margin-top: 1px;
    padding: 30px 31px;
    width: 254px;
    font-size: 23px;
    gap: 19.5px;
  }
  @media screen and (max-width: 1279px) and (min-width: 1024px) {
    transform: translate(-10px, 1px);
    //margin-top: 2px;
    padding: 29px 37px;
    width: 218px;
    font-size: 19px;
    gap: 28px;
  }
`;

const DropDownItem = styled.div`
  cursor: pointer;

  &:hover {
    color: #111133;
  }
`;
