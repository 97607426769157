import React from "react";
import styled from "styled-components";
import mEvent from "../../assets/images/mBlogEvent.png";
import dEvent from "../../assets/images/dBlogEvent.png";
import { useNavigate } from "react-router-dom";

interface CompleteModalProps {
    onClose: () => void;
}

const CompleteModal: React.FC<CompleteModalProps> = ({ onClose }) => {
    const navigate = useNavigate();
    return (
        <Overlay>
            <a href={"https://blog.naver.com/houber/223672079986"} target={"_blank"}>
                <ModalBox>
                    <ModalClose onClick={() => navigate("/")}>×</ModalClose>
                </ModalBox>
            </a>

         </Overlay>
    );
};
export default CompleteModal;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalBox = styled.div`
    position: relative;
    width: 279px;
    height: 395px;
    background-image: url(${mEvent});
    border-radius: 12px;
    z-index: 11;
    @media screen and (min-width: 670px) {
        width: 452px;
        height: 379px;
        background-image: url(${dEvent});
    }
`;
const ModalClose = styled.button`
    position: absolute;
    top: 80px;
    right: 10px;
    z-index: 100; // ✅ 다른 것보다 위에 있도록
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
    color: #fff;

    &:hover {
        color: #ff2222;
    }
    @media screen and (min-width: 670px) {
        top: 30px;
    }
`;
