import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import svg from "../../assets/images/vector370.png";

interface OptionType {
    label: string; // 화면에 보여줄 텍스트
    value: string; // 서버에 보낼 값
}

interface DropdownProps{
    ref?: React.RefObject<HTMLDivElement>; // ✅ 추가됨
    options: OptionType[];
    selectedValue: OptionType | null;
    setSelectedValue: (value: OptionType) => void;
    hasError?: boolean;
}
const DropdownContainer = styled.div`
    position: relative;
    width: 100%;
`;

const DropdownButton = styled.button<{ hasError?: boolean }>`
    width: 100%;
    padding: 20px;
    font-size: 15px;
    background-color: white;
    color: #000;
    border: 1px solid ${({ hasError }) => (hasError ? "red" : "#ddd")};
    border-radius: 5px;
    text-align: left;
    cursor: pointer;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 14px; /* 아이콘 크기 */
        height: 14px;
        background-image: url(${svg}); /* ✅ 이미지 적용 */
        background-size: contain;
        background-repeat: no-repeat;
    }
`;

const DropdownList = styled.ul<{ isOpen: boolean }>`
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 6px;
    padding: 0;
    list-style: none;
    z-index: 10;
    display: ${(props) => (props.isOpen ? "block" : "none")};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const DropdownItem = styled.li`
    padding: 12px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        background-color: #f1f1f1;
    }
`;

const Dropdown: React.FC<DropdownProps> = ({
       ref,
       options,
       selectedValue,
       setSelectedValue,
       hasError = false,
                   }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSelect = (option: OptionType) => {
        setSelectedValue(option); // 부모 컴포넌트로 값 넘김
        setIsOpen(false);
    };
// ✅ 외부 클릭 감지
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        // 클린업
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);
    return (
        <DropdownContainer ref={ref ?? dropdownRef}>
            <DropdownButton
                onClick={toggleDropdown}
                hasError={hasError}
                data-layer={selectedValue?.value ?? ""}
            >
                {selectedValue ? selectedValue.label : "선택하기"}
            </DropdownButton>

            <DropdownList isOpen={isOpen}>
                {options.map((option, index) => (
                    <DropdownItem key={index} onClick={() => handleSelect(option)}>
                        {option.label}
                    </DropdownItem>
                ))}
            </DropdownList>
        </DropdownContainer>
    );
};


export default Dropdown;
