// src/pages/hiddenPage/HiddenPage.tsx
import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import logoIcon from "../../assets/images/logo.png";
import svg from "../../assets/images/vector370.png";
import svg2 from "../../assets/images/vector365.png";
import Dropdown from "../../components/Hidden/HiddenDropdown";
import HiddenPrivacyBox from "../../components/Hidden/HiddenPrivacyBox";
import HiddenFloatingBox from "../../components/Hidden/HiddenFloatingBox";
import CompleteModal from "../../components/Hidden/CompleteModal";

interface OptionType {
    label: string; // 화면에 보여줄 텍스트
    value: string; // 서버에 보낼 값
}
const HiddenPage: React.FC = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1440);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 670);

    const [selectedVisaType, setSelectedVisaType] = useState<OptionType | null>(null);
    const [selectedServiceType, setSelectedServiceType] = useState<OptionType | null>(null);
    const [selectedRoomType, setSelectedRoomType] = useState<OptionType | null>(null);
    const [selectedSource, setSelectedSource] = useState<OptionType | null>(null);

    const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
    const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
    const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [kakaoId, setKakaoId] = useState("");
    const [desiredArea, setDesiredArea] = useState("");
    const [desiredPrice, setDesiredPrice] = useState("");
    const [desiredDate1, setDesiredDate1] = useState("");
    const [desiredDate2, setDesiredDate2] = useState("");
    const [activeTags, setActiveTags] = useState<string[]>([]);
    // const [description, setDescription] = useState("");

    const [errorFields, setErrorFields] = useState<Set<string>>(new Set());
    const [isFormValid, setIsFormValid] = useState(false);
    const [sourceDescription, setSourceDescription] = useState("");


    const nameRef = useRef<HTMLInputElement>(null);
    const phoneRef = useRef<HTMLInputElement>(null);
    const kakaoIdRef = useRef<HTMLInputElement>(null);
    const visaTypeRef = useRef<HTMLDivElement>(null);
    const serviceTypeRef = useRef<HTMLDivElement>(null);
    const desiredAreaRef = useRef<HTMLInputElement>(null);
    const desiredPriceRef = useRef<HTMLInputElement>(null);
    const roomTypeRef = useRef<HTMLDivElement>(null);
    const desiredDate1Ref = useRef<HTMLInputElement>(null);
    const desiredDate2Ref = useRef<HTMLInputElement>(null);
    const sourceRef = useRef<HTMLDivElement>(null);
    const sourceDescriptionRef = useRef<HTMLInputElement>(null);
    const privacyRef = useRef<HTMLInputElement>(null);

    const visaOptions = [
        { label: "워킹홀리데이", value: "WH" },
        { label: "유학비자", value: "SA"},
        { label: "취업비자", value: "EMPLOY" },
        { label: "투자경영비자", value: "IMV" },
        { label: "배우자비자", value: "SV" },
        { label: "정주권", value: "ROS" },
        { label: "영주권", value: "PR" },
        { label: "일본국적", value: "JN" },
        { label: "그 외", value: "OTHER" },

    ];
    const serviceOptions = [
        { label: "임대", value: "RENTAL" },
        { label: "매매", value: "SALE"},
        { label: "점포", value: "STORE" }
    ];
    const roomTypeOptions = [
        { label: "아직 잘 모르겠어요", value: "OTHER" },

        { label: "1R", value: "R1" },
        { label: "1K", value: "K1" },
        { label: "1DK", value: "DK1" },
        { label: "1LDK", value: "LDK1" },

        { label: "2R", value: "R2" },
        { label: "2K", value: "K2" },
        { label: "2DK", value: "DK2" },
        { label: "2LDK", value: "LDK2" },

        { label: "3R", value: "R3" },
        { label: "3K", value: "K3" },
        { label: "3DK", value: "DK3" },
        { label: "3LDK", value: "LDK3" },

        { label: "4R", value: "R4" },
        { label: "4K", value: "K4" },
        { label: "4DK", value: "DK4" },
        { label: "4LDK", value: "LDK4" },

        { label: "5R", value: "R5" },
        { label: "5K", value: "K5" },
        { label: "5DK", value: "DK5" },
        { label: "5LDK", value: "LDK5" },
    ];
    const sourceOptions = [
        { label: "하우버 사이트", value: "1" },
        { label: "하우버 블로그", value: "2" },
        { label: "네이버 입주 후기", value: "3" },
        { label: "유튜브", value: "12" },
        { label: "인스타그램", value: "13" },
        { label: "인터넷 검색", value: "6" },
        { label: "지인 소개", value: "10" },
        { label: "어학원 소개", value: "8" },
        { label: "유학원 소개", value: "7" },
        { label: "외부 블로그", value: "5" },
        { label: "544카페", value: "4" },
        { label: "바나나 워홀 사이트", value: "9" },
        { label: "기타", value: "14" },
    ];
    const TagOptions = [
        { label: "#조용한 동네", value: "1" },
        { label: "#공원근처", value: "2" },
        { label: "#산책&운동", value: "3" },
        { label: "#최신 설비", value: "4" },
        { label: "#넓은 방", value: "5" },
        { label: "#채광", value: "6" },
        { label: "#가격", value: "7" },
        { label: "#역세권", value: "8" },
        { label: "#치안", value: "9" },
        { label: "#학교근접", value: "10" },
        { label: "#현지인 많은 곳", value: "11" },
        { label: "#자연환경", value: "12" },
        { label: "#편의시설", value: "13" },
        { label: "#MBTI - I", value: "14" },
        { label: "#MBTI - E", value: "15" },
        { label: "#핫플", value: "16" },
        { label: "#애완견 가능", value: "17" },
        { label: "#2인 입주", value: "18" },
        { label: "#매매&점포", value: "19" }
    ]

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 670);
            setIsWideScreen(window.innerWidth >= 1440);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (selectedSource?.value !== "7") {
            setSourceDescription("");
            updateFieldError("sourceDescription", true);
        }
    }, [selectedSource]);

    const sanitizeInput = (value: string) => {

        return value
            .replace(/</g, "")
            .replace(/>/g, "")
            .replace(/"/g, "")
            .replace(/'/g, "")
            .replace(/;/g, "")
            .replace(/\(/g, "")
            .replace(/\)/g, "")
            .replace(/`/g, "");
    };
    const updateFieldError = (fieldName: string, isValid: boolean) => {
        setErrorFields((prev) => {
            const updated = new Set(prev);
            if (isValid) {
                updated.delete(fieldName);
            } else {
                updated.add(fieldName);
            }
            return updated;
        });
    };
    useEffect(() => {
        const storedSubmission = localStorage.getItem("consultFormSubmitted");
        if (storedSubmission === "true") {
            setIsSubmitted(true);
        }
    }, []);

    useEffect(() => {
        const isValidInput = (value: string) => sanitizeInput(value).trim().length > 0;
        const hasDesiredDate = isValidInput(desiredDate1) && isValidInput(desiredDate2);

        const isValid =
            isValidInput(name) &&
            isValidInput(phone) &&
            isValidInput(kakaoId) &&
            selectedVisaType?.value &&
            selectedServiceType?.value &&
            isValidInput(desiredArea) &&
            isValidInput(desiredPrice) &&
            selectedRoomType?.value &&
            hasDesiredDate &&
            selectedSource?.value &&
            isPrivacyChecked;

        setIsFormValid(Boolean(isValid));
    }, [
        name,
        phone,
        kakaoId,
        selectedVisaType,
        selectedServiceType,
        desiredArea,
        desiredPrice,
        selectedRoomType,
        desiredDate1,
        desiredDate2,
        selectedSource,
        isPrivacyChecked
    ]);

    const validateAndScroll = () => {
        const newErrorFields = new Set<string>();

        if (!sanitizeInput(name)) newErrorFields.add("name");
        if (!sanitizeInput(phone)) newErrorFields.add("phone");
        if (!sanitizeInput(kakaoId)) newErrorFields.add("kakaoId");
        if (!selectedVisaType?.value) newErrorFields.add("visaType");
        if (!selectedServiceType?.value) newErrorFields.add("serviceType");
        if (!sanitizeInput(desiredArea)) newErrorFields.add("desiredArea");
        if (!sanitizeInput(desiredPrice)) newErrorFields.add("desiredPrice");
        if (!selectedRoomType?.value) newErrorFields.add("roomType");
        if (!sanitizeInput(desiredDate1)) newErrorFields.add("desiredDate1");
        if (!sanitizeInput(desiredDate2)) newErrorFields.add("desiredDate2");
        if (!selectedSource?.value) newErrorFields.add("source");
        if (selectedSource?.value === "7") {
            if (!sanitizeInput(sourceDescription)) {
                newErrorFields.add("sourceDescription");
            }
        }
        if (!isPrivacyChecked) newErrorFields.add("privacy");

        setErrorFields(newErrorFields);

        if (newErrorFields.size > 0) {
            const firstError = Array.from(newErrorFields)[0];

            switch (firstError) {
                case "name":
                    nameRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                    break;
                case "phone":
                    phoneRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                    break;
                case "kakaoId":
                    kakaoIdRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                    break;
                case "visaType":
                    visaTypeRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                    break;
                case "serviceType":
                    serviceTypeRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                    break;
                case "desiredArea":
                    desiredAreaRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                    break;
                case "desiredPrice":
                    desiredPriceRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                    break;
                case "roomType":
                    roomTypeRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                    break;
                case "desiredDate1":
                    desiredDate1Ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                    break;
                case "desiredDate2":
                    desiredDate2Ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                    break;
                case "source":
                    sourceRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                    break;
                case "sourceDescription":
                    sourceDescriptionRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                    break;
                case "privacy":
                    privacyRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
                    break;
                default:
                    break;
            }
        }

        return newErrorFields.size === 0;
    };

    const limitStringLength = (value: string, maxLength: number = 200) => {
        return value.length > maxLength ? value.substring(0, maxLength) : value;
    };
    const handleSubmit = async () => {
        const isValid = validateAndScroll();

        if (!isValid) return;
        if (isSubmitted) {
            alert("이미 신청 완료 되었습니다. 메인으로 이동합니다");
            window.location.assign("/");
            return;
        }
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        const dateSum = `${desiredDate1 || ""} ~ ${desiredDate2 || ""}`;
        const formattedDate = new Date().toISOString().split("T")[0];

        const data = {
            at: `Bearer ${process.env.REACT_APP_ADERS_ACCESS_KEY}`,
            contractStatus: "IN_CONSULT",
            clientType: selectedServiceType?.value,
            visaType: selectedVisaType?.value,
            phone: limitStringLength(phone),
            clientName: limitStringLength(name),
            consultationStartDate: formattedDate,
            consultantHash: "aders-temp",
            kakaoId: limitStringLength(kakaoId),
            areaNote: limitStringLength(desiredArea),
            supportPrice: Number(desiredPrice),
            roomType: selectedRoomType?.value,
            desiredMoveInDate: limitStringLength(dateSum),
            clientStationDto: null,
            inflowPathId: Number(selectedSource?.value),
            supportNote: selectedSource?.value === "7" ? sourceDescription : null,
            tagList: activeTags ? activeTags.map(tag => Number(tag)) : null
        };
        console.log(data);
        try {
            const response = await fetch("https://www.aderspro.com/aders/houber/form", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                if (response.status === 401)
                    return alert("무결성 검증 실패, 페이지를 새로고침해주세요")
                if (response.status === 404)
                    return alert("서버가 응답하지 않습니다")
                else
                    return alert("서버 오류")

            }

            setIsSubmitted(true);
            localStorage.setItem("consultFormSubmitted", "true");
            setIsCompleteModalOpen(true)
        } catch (error) {
            console.error(error);
            return alert("서버 오류")
        } finally {
            setIsLoading(false);
        }
    };
    const handleTagClick = (value: string) => {
        setActiveTags(prev => {
            if (prev.includes(value)) {
                return prev.filter(tag => tag !== value);
            } else {
                if (prev.length >= 3) {
                    return prev;
                }
                return [...prev, value];
            }
        });
    };
    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex,nofollow" />
            </Helmet>
            <HiddenHeader>
                <Container style={{ padding: 0 }}>
                    <LogoBox>
                        <a href={"/"}>
                            <Logo src={logoIcon} alt="Logo" />
                        </a>
                    </LogoBox>
                </Container>
            </HiddenHeader>
            <Container>
                <Title>상담 양식</Title>
                <Subtitle>
                    {isSmallScreen ? (
                        <>
                            상담 양식을 작성해주시면 <br />
                            <Highlight>하우버 부동산 카카오톡 채널</Highlight>로 연락드리겠습니다.
                        </>
                    ) : (
                        <>상담 양식을 작성해주시면 <Highlight>하우버 부동산 카카오톡 채널</Highlight>로 연락드리겠습니다.</>
                    )}
                </Subtitle>
                <Notice>
                    <Star>*</Star> 은 필수입력 항목입니다.
                </Notice>

                <Divider />

                <FormGroup>
                    <Label>이름<Star>*</Star></Label>
                    <Input
                        ref={nameRef}
                        type="text"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                            updateFieldError("name", sanitizeInput(e.target.value).trim().length > 0);
                        }}
                        autoComplete="off"
                        placeholder={"성함을 입력해주세요"}
                        style={{
                            borderColor: errorFields.has("name") ? "red" : "#ddd",
                        }}
                    />
                    <EmptyNotice visible={errorFields.has("name")}>* 필수 항목을 입력해주세요</EmptyNotice>
                </FormGroup>
                <FormGroup>
                    <Label>전화번호<Star>*</Star></Label>
                    <Input
                        ref={phoneRef}
                        type="text"
                        value={phone}
                        onChange={(e) => {
                            setPhone(e.target.value);
                            updateFieldError("phone", sanitizeInput(e.target.value).trim().length > 0);
                        }}
                        autoComplete="off"
                        placeholder={"010-0000-0000"}
                        style={{
                            borderColor: errorFields.has("phone") ? "red" : "#ddd",
                        }}
                    />
                    <EmptyNotice visible={errorFields.has("phone")}>* 필수 항목을 입력해주세요</EmptyNotice>
                </FormGroup>

                <FormGroup>
                    <Label>카카오톡 아이디<Star>*</Star></Label>
                    <Input
                        ref={kakaoIdRef}
                        type="text"
                        value={kakaoId}
                        onChange={(e) => {
                            setKakaoId(e.target.value);
                            updateFieldError("kakaoId", sanitizeInput(e.target.value).trim().length > 0);
                        }}
                        autoComplete="off"
                        placeholder={"카카오 아이디를 입력해주세요"}
                        style={{
                            borderColor: errorFields.has("kakaoId") ? "red" : "#ddd",
                        }}
                    />
                    <EmptyNotice visible={errorFields.has("kakaoId")}>* 필수 항목을 입력해주세요</EmptyNotice>
                </FormGroup>

                <RowFormSection>
                    <RowFormGroup>
                        <Label>비자 종류<Star>*</Star></Label>
                        <Dropdown
                            ref={visaTypeRef}
                            options={visaOptions}
                            selectedValue={selectedVisaType}
                            setSelectedValue={(option) => {
                                setSelectedVisaType(option);
                                updateFieldError("visaType", option !== null);
                            }}
                            hasError={errorFields.has("visaType")}
                        />
                        <EmptyNotice visible={errorFields.has("visaType")}>* 필수 항목을 입력해주세요</EmptyNotice>
                    </RowFormGroup>
                    <RowFormGroup>
                        <Label>희망 매물 종류<Star>*</Star></Label>
                        <Dropdown
                            ref={serviceTypeRef}
                            options={serviceOptions}
                            selectedValue={selectedServiceType}
                            setSelectedValue={(option) => {
                                setSelectedServiceType(option);
                                updateFieldError("serviceType", option !== null);
                            }}
                            hasError={errorFields.has("serviceType")}
                        />
                        <EmptyNotice visible={errorFields.has("serviceType")}>* 필수 항목을 입력해주세요</EmptyNotice>
                    </RowFormGroup>
                </RowFormSection>
                <FormGroup>
                    <Label>희망 지역<Star>*</Star></Label>
                    <Input
                        ref={desiredAreaRef}
                        type="text"
                        value={desiredArea}
                        onChange={(e) => {
                            setDesiredArea(e.target.value);
                            updateFieldError("desiredArea", sanitizeInput(e.target.value).trim().length > 0);
                        }}

                        autoComplete="off"
                        placeholder="ex) 오사카 난바"
                        style={{
                            borderColor: errorFields.has("desiredArea") ? "red" : "#ddd",
                        }}
                    />
                    <EmptyNotice visible={errorFields.has("desiredArea")}>* 필수 항목을 입력해주세요</EmptyNotice>
                </FormGroup>
                <RowFormSection>
                    <RowFormGroup>
                        <Label>희망 월세<Star>*</Star></Label>
                        <PriceInputWrapper>
                            <PriceInput
                                ref={desiredPriceRef}
                                type="text"
                                value={desiredPrice}
                                onChange={(e) => {
                                    const rawValue = e.target.value;
                                    const sanitizedValue = rawValue.replace(/[^0-9]/g, ""); // 숫자만 남김
                                    setDesiredPrice(sanitizedValue);
                                    updateFieldError("desiredPrice", sanitizeInput(sanitizedValue).trim().length > 0);
                                }}
                                placeholder="숫자를 입력해주세요"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                autoComplete="off"
                                style={{
                                    borderColor: errorFields.has("desiredPrice") ? "red" : "#ddd",
                                }}
                            />
                            <CurrencyLabel>엔 (¥)</CurrencyLabel>
                        </PriceInputWrapper>
                        <EmptyNotice visible={errorFields.has("desiredPrice")}>* 필수 항목을 입력해주세요</EmptyNotice>
                    </RowFormGroup>
                    <RowFormGroup>
                        <Label>방 타입<Star>*</Star></Label>
                        <Dropdown
                            ref={roomTypeRef}
                            options={roomTypeOptions}
                              selectedValue={selectedRoomType}
                              setSelectedValue={(option) => {
                                  setSelectedRoomType(option);
                                  updateFieldError("roomType", option !== null);
                              }}
                              hasError={errorFields.has("roomType")}
                        />
                        <EmptyNotice visible={errorFields.has("roomType")}>* 필수 항목을 입력해주세요</EmptyNotice>
                    </RowFormGroup>
                </RowFormSection>

                <FormGroup>
                    <Label>입주 희망 날짜<Star>*</Star></Label>
                    <DateInputArea>
                        <DateDiv style={{flex: "1", minWidth: "0"}}>
                            <Input
                                ref={desiredDate1Ref}
                                type="text"
                                value={desiredDate1}
                                onChange={(e) => {
                                    setDesiredDate1(e.target.value);
                                    updateFieldError("desiredDate1", sanitizeInput(e.target.value).trim().length > 0);
                                }}
                                autoComplete="off"
                                placeholder={"ex) 00년 0월 초"}
                                style={{
                                    borderColor: errorFields.has("desiredDate1") ? "red" : "#ddd",
                                }}
                            />
                            <EmptyNotice visible={errorFields.has("desiredDate1")}>* 필수 항목을 입력해주세요</EmptyNotice>
                        </DateDiv>
                        <DividerText> ~ </DividerText>
                        <DateDiv style={{flex: "1", minWidth: "0"}}>
                            <Input
                                ref={desiredDate2Ref}
                                type="text"
                                value={desiredDate2}
                                onChange={(e) => {
                                    setDesiredDate2(e.target.value);
                                    updateFieldError("desiredDate2", sanitizeInput(e.target.value).trim().length > 0);
                                }}
                                autoComplete="off"
                                placeholder={"ex) 00년 0월 말"}
                                style={{
                                    borderColor: errorFields.has("desiredDate2") ? "red" : "#ddd",
                                }}
                            />
                            <EmptyNotice visible={errorFields.has("desiredDate2")}>* 필수 항목을 입력해주세요</EmptyNotice>
                        </DateDiv>
                    </DateInputArea>
                </FormGroup>

                <FormGroup>
                    <Label>유입경로<Star>*</Star></Label>
                    <Dropdown
                        ref={sourceRef}
                        options={sourceOptions}
                        selectedValue={selectedSource}
                        setSelectedValue={(option) => {
                            setSelectedSource(option);
                            updateFieldError("source", option !== null);
                        }}
                        hasError = {errorFields.has("source")}
                    />
                    {selectedSource?.value === "7" && (
                        <HiddenFormGroup>
                            <Input
                                ref={sourceDescriptionRef}
                                type="text"
                                value={sourceDescription}
                                onChange={(e) => {
                                    setSourceDescription(e.target.value);
                                    updateFieldError(
                                        "sourceDescription",
                                        sanitizeInput(e.target.value).trim().length > 0
                                    );
                                }}
                                autoComplete="off"
                                placeholder={"ex) 유학원 이름을 입력해주세요"}
                                style={{
                                    borderColor: errorFields.has("sourceDescription") ? "red" : "#ddd",
                                }}
                            />
                            <EmptyNotice visible={errorFields.has("sourceDescription")}>
                                * 유학원 이름을 입력해주세요
                            </EmptyNotice>
                        </HiddenFormGroup>
                    )}
                    <EmptyNotice visible={errorFields.has("source")}>* 필수 항목을 입력해주세요</EmptyNotice>
                </FormGroup>

                {/*<FormGroup>*/}
                {/*    <Label>추가 문의 내용</Label>*/}
                {/*    <Textarea*/}
                {/*        autoComplete="off"*/}
                {/*        placeholder="자유롭게 적어주세요."*/}
                {/*        value={description}*/}
                {/*        maxLength={255}*/}
                {/*        onChange={(e) => setDescription(e.target.value)}*/}
                {/*    />*/}
                {/*</FormGroup>*/}
                <FormGroup style={{boxSizing: "border-box"}}>
                    <Label>내가 원하는 일본 생활 키워드</Label>
                    <p style={{marginBottom: "20px", opacity: "60%"}}>최대 키워드 3가지를 선택해주세요!</p>
                    <TagArea>
                        {
                            TagOptions.map(item => {
                                return (
                                    <Tag
                                        key={item.value}
                                        data-layer={item.value}
                                        active={activeTags.includes(item.value)}
                                        onClick={() => handleTagClick(item.value)}
                                    >
                                        {item.label}
                                    </Tag>
                                );
                            })}
                    </TagArea>
                </FormGroup>

                <Divider />
                <PrivacyGroup>
                    <PrivacyLabel>
                        <p>개인정보 동의<Star>*</Star></p>
                        <p style={{ cursor: "pointer", paddingLeft: "20px", fontWeight: "normal", fontSize: "12px", color: "#8491A7" }}
                           onClick={()=>setIsPrivacyOpen(true)}
                        >
                            상세보기
                            <img src={svg} style={{ width: "12px", marginLeft: "10px", transform: "rotate(-90deg)" }} />
                        </p>
                        {isPrivacyOpen && (
                            <HiddenPrivacyBox onClose={() => setIsPrivacyOpen(false)} />
                        )}
                    </PrivacyLabel>
                    <PrivacySection>
                        <CustomCheckbox
                            ref={privacyRef}
                            id="privacy"
                            checked={isPrivacyChecked}
                            onChange={(e) => {
                                setIsPrivacyChecked(e.target.checked);
                                updateFieldError("privacy", e.target.checked);
                            }}
                            style={{
                                borderColor: errorFields.has("privacy") ? "red" : "#ddd",
                            }}
                        />
                        <PrivacyText htmlFor="privacy">
                            개인정보 처리방침을 확인하였으며, 이에 동의합니다.
                        </PrivacyText>
                        <EmptyNotice visible={errorFields.has("privacy")}>* 필수 항목을 입력해주세요</EmptyNotice>
                    </PrivacySection>

                </PrivacyGroup>
                {
                    !isWideScreen && (
                        <SubmitButton
                            layer = {!isFormValid || isLoading}
                            onClick={handleSubmit}
                        >
                            작성 완료
                        </SubmitButton>
                    )
                }

            </Container>
            {isWideScreen && (
                <HiddenFloatingBox
                    itemStatus={{
                        name: Boolean(name.trim()),
                        phone: Boolean(phone.trim()),
                        kakaoId: Boolean(kakaoId.trim()),
                        visaType: Boolean(selectedVisaType?.value),
                        serviceType: Boolean(selectedServiceType?.value),
                        desiredArea: Boolean(desiredArea.trim()),
                        desiredPrice: Boolean(desiredPrice.trim()),
                        roomType: Boolean(selectedRoomType?.value),
                        desiredDate: Boolean(desiredDate1.trim() && desiredDate2.trim()),
                        source: Boolean(selectedSource?.value),
                        tags: Boolean(activeTags.length > 0),
                        isPrivacyChecked,
                        isFormValid
                    }}
                    onSubmit={handleSubmit}
                />
            )}
            {isCompleteModalOpen && (
                <CompleteModal onClose={() => setIsCompleteModalOpen(false)} />
            )}
        </>
    );
};

export default HiddenPage;

const TagArea = styled.section`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 8px 12px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
`;
const Tag = styled.button<{ active?: boolean }>`
    width: auto;
    height: 38px;
    padding: 0 16px;
    font-size: 15px;
    white-space: nowrap;
    align-content: center;
    text-align: center;
    border: 1px solid ${props => (props.active ? '#0222B4' : '#E5E5E5')};  // ✅ 테두리
    outline: 1px solid ${props => (props.active ? '#0222B4' : '#E5E5E5')};
    background-color: #fff;
    border-radius: 10px;
    line-height: 26px;
    color: ${props => (props.active ? '#0222B4' : '#000')};
    cursor: pointer;
    box-sizing: border-box;
    font-family: "Noto Sans KR";
    &:hover {
        border: 1px solid ${props => (props.active ? '#0222B4' : '#aaa')};  // ✅ 테두리
        outline: 1px solid ${props => (props.active ? '#0222B4' : '#aaa')};  // ✅ 테두리
    }
`;

const HiddenHeader = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    background: #fff;
    height: 56px;
    box-shadow: 0 4px 4px -2px rgba(55, 63, 87, 0.10);
    margin-bottom: 40px;
`;
const LogoBox = styled.div`
    margin: 0 0 0 24px;
    max-width: 100%;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
`;
const Logo = styled.img`
    width: 70px;
    height: 30px;
`;
const SubmitButton = styled.button<{layer: boolean}>`
    display: block;
    position: sticky;
    bottom: 24px;
    z-index: 2;
    width: 100%;
    padding: 18px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 20px;
    
    background-color: ${(props) => (props.layer ? "#8E8E8E" : "#0222B4")};
    transition: background-color 0.3s ease;
    
    box-shadow: 0 0 10px 15px #fff ;
    &:hover {
        background-color: ${(props) => (props.layer ? "#8E8E8E" : "#001887")};
    }
`;

const Container = styled.section`
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 24px 24px 24px;
    background-color: #fff;
    box-sizing: border-box;
    color: #000;

    @media screen and (min-width: 670px) {
        max-width: 670px;
    }
`;

const Title = styled.h1`
    font-size: 26px;
    font-weight: bold;
    line-height: 54px;
    margin-bottom: 16px;
    color: #000;
`;

const Subtitle = styled.p`
    font-size: 15px;
    color: #000;
    line-height: 26px;
    margin-bottom: 2px;
    white-space: nowrap; /* 기본적으로 한 줄 유지 */
    text-align: left;
`;
const Highlight = styled.span`
    font-weight: bold;
    color: #000;
`;

const Notice = styled.p`
    font-size: 12px;
    line-height: 26px;
    color: #8F8F8F;
`;
const Star = styled.span`
    font-size: 16px;
    margin-left: 2px;
    line-height: 26px;
    color: #0222B4;
`;
const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #DADFEB;
    margin: 30px 0;
`;
const DividerText = styled.p`
  margin: 0;
  text-align: center;
  min-width: 20px;
`;
const FormGroup = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 38px 0;
    max-width: 100%;
`;
const HiddenFormGroup = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 10px 0;
    max-width: 100%;
`;

const EmptyNotice = styled.p<{ visible?: boolean }>`
    position: absolute;
    left: 0;
    bottom: -25px;
    display: ${({ visible }) => (visible ? "block" : "none")};
    font-size: 12px;
    line-height: 26px;
    color: red;
`
const RowFormSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 38px;
    margin: 0;
    @media screen and (min-width: 670px) {
        flex-direction: row;
    }
`;
const RowFormGroup = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 670px) {
        flex: 1;
    }
`;

const Label = styled.label`
    font-size: 16px;
    line-height: 26px;
    font-weight: bold;
    margin-bottom: 12px;
`;
const DateInputArea = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
`;
const DateDiv = styled.div`
    position: relative;
    box-sizing: border-box;
`;
const Input = styled.input`
    padding: 20px;
    width: 100%;
    font-size: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
`;

const PriceInputWrapper = styled.div`
    position: relative;
    width: 100%;
    height: auto;
`;
const PriceInput = styled.input`
    width: 100%;
    padding: 21px 60px 20px 20px;
    font-size: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield; /* Chrome, Edge, Safari */

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; /* 일부 브라우저에서 여백이 생기는 문제 방지 */
    }
`;

const CurrencyLabel = styled.span`
    position: absolute;
    line-height: 26px;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 15px;
    color: #000;
`;

const Textarea = styled.textarea`
  height: 100px;
  resize: none;
  padding: 15px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
`;
const PrivacyGroup = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 38px 0;
    max-width: 100%;
    
    @media screen and (min-width: 1440px) {
        margin-bottom: 142px;
    }
`;
const PrivacyLabel = styled.div`
    font-size: 16px;
    line-height: 26px;
    font-weight: bold;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
`;
const CustomCheckbox = styled.input.attrs({ type: "checkbox" })`
    width: 20px;
    height: 20px;
    margin: 0;
    cursor: pointer;
    appearance: none;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    transition: all 0.2s ease;
    position: relative;

    &:checked {
        background-color: #000;
        border-color: #000;
    }

    &:checked::after {
        content: "";
        display: block;
        width: 10px;  // ✅ 너가 원하는 크기로 조정
        height: 10px;
        background-image: url(${svg2});
        background-size: contain;  // ✅ 이미지가 크기에 맞게 들어가도록
        background-repeat: no-repeat;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:focus {
        outline: none;
    }
`;
const PrivacySection = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;

`;

const PrivacyText = styled.label`
  margin-left: 10px;
`;


